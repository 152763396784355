/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
require('@fontsource-variable/red-hat-text');
require('@fontsource/bebas-neue');
require('@fontsource-variable/baloo-2');
// normalize CSS across browsers
require('./src/normalize.css');
// custom CSS styles
require('./src/bootstrap-custom.css');
require('./src/style.css');

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);
  setTimeout(() => { window.scrollTo(...(currentPosition || [0, 0])); }, 100);

  return false;
};
